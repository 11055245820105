import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { getImage } from 'gatsby-plugin-image'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import Moment from 'react-moment'
import Clock from 'images/schedule.svg'
import { Seo, Layout } from '../components/Layout'
import { ShareButtons, DiscoverButton } from '../components/Shared'
import Section from '../components/Layout/Section'
import RelatedBlogsSlice from '../components/Slices/RelatedBlogsSlice'
import {
  ArticleContent,
  Content,
  H2,
  ImageContainer,
  MarginBottom,
  Navigation,
  NavigationHeader,
  SmallText,
  StyledFlag,
  StyledImage,
  StyledSubtitle,
  SubHeader,
  SubHeaderTime,
  TagsContainer,
  TimeToRead,
  Title,
  ShareContainer
} from '../utils/templates/styles'
import Tag from '../components/Blog/Tag'
import { TableOfContents, extractHeadings } from '../components/Shared/ContentTable'

deckDeckGoHighlightElement()


const BlogPage = ({ pageContext: { page: pageData } }) => {
  const {
    childContentfulBlogPostContentTextNode: {
      childMarkdownRemark: { html, timeToRead },
    },
    slug,
    shortDescription,
    metaDescription: { metaDescription },
    heroImage,
    title,
    firstPublishedAt,
    topicTags,
    updatedAt,
  } = pageData
  const [content, setContent] = useState({ headings: [], html: '' })
  const theme = useTheme()

  useEffect(() => {
    const { headings, updatedHtml } = extractHeadings(html)
    setContent({ headings, html: updatedHtml })
  }, [html])

  const pathname = `blog-ab-testing/${slug}`
  const image = getImage(heroImage)

  const script = [
    {
      '@type': 'BlogPosting',
      headline: title,
      datePublished: firstPublishedAt,
      dateModified: updatedAt,
      image: image?.images?.fallback?.src ?? image?.images?.sources[0],
      author: [
        {
          '@type': 'Organization',
          name: 'Semplates',
          url: 'https://semplates.io/',
        },
      ],
    },
  ]

  const breadcrumbList = [
    { position: 1, name: 'Blog', path: 'blog' },
    { position: 2, name: title, path: pathname },
  ]

  return (
    <Layout>
      <Seo
        title={title}
        keywords={topicTags}
        description={metaDescription}
        heroImage={heroImage}
        pathname={pathname}
        script={script}
        breadcrumbList={breadcrumbList}
      />
      <Section bgStyle="darkTop">
        <ImageContainer>
          <StyledImage image={image} alt={heroImage?.description ?? title} />
        </ImageContainer>
        <ShareContainer>
          <ShareButtons
            url={`https://semplates.io/${pathname}`}
            title={title}
            description={shortDescription}
          />
        </ShareContainer>
        <Content>
          <div>
            <SmallText>Home &nbsp;&gt;&nbsp; Resources &nbsp;&gt;&nbsp; All Blog posts</SmallText>
            <Title>{title}</Title>
            <SubHeader>
              <TagsContainer>
                {topicTags.map(tag => (
                  <Tag key={tag} tag={tag} />
                ))}
              </TagsContainer>
              <SubHeaderTime>
                <div>
                  <Moment
                    date={new Date(firstPublishedAt)}
                    format="DD MMM YY"
                  />
                  {' - '}
                  Updated &nbsp;
                  <Moment date={new Date(updatedAt)} format="DD MMM YY" />
                </div>
                <TimeToRead>
                  <Clock />
                  <SmallText>&nbsp;{timeToRead} min read</SmallText>
                </TimeToRead>
              </SubHeaderTime>
            </SubHeader>
            {shortDescription && (
              <StyledSubtitle>
                <p>{shortDescription}</p>
              </StyledSubtitle>
            )}
            <ArticleContent
              dangerouslySetInnerHTML={{ __html: content.html }}
              onClick={(e) => {
                if (e.target.tagName === 'A') {
                  const { href } = e.target;

                  if (href.includes('semplates.io')) {
                    e.preventDefault();
                    window.location.href = href;
                  }
                }
              }}
            />
            <H2>Any question?</H2>
            <MarginBottom>
              <p>Feel free to <a href="mailto:support@semplates.io">contact us</a> anytime if you have any questions.</p>
            </MarginBottom>
            <DiscoverButton color={theme.palette.primary.main} to="/blog">
              Return to all blog posts
            </DiscoverButton>
          </div>
          <div>
            <Navigation>
              <NavigationHeader>
                <StyledFlag />
                <p>On this page</p>
              </NavigationHeader>
              <TableOfContents headings={content.headings} />
            </Navigation>
          </div>
        </Content>
      </Section>
      <RelatedBlogsSlice tags={topicTags} title={title} />
    </Layout>
  )
}

export default BlogPage
